import React, { useState, useRef } from 'react';
import '../styles/Navbar.css';
import Hambuger from "../svgs/hambuger.svg"
import timesButton from "../svgs/timesButton.svg"
import { NavLink, useNavigate } from 'react-router-dom';
import logoImage from "../../src/images/logoImage.png"

const Navbar = () => {
    const navmove = useNavigate()
    const [showNav, setsetShowNav] = useState(false);
    const navMenu= useRef(null);
    const toggleMenu = () => {
        setsetShowNav(!showNav);
        navMenu.current.classList = [];
        if (!showNav){
            navMenu.current.classList.add('nav-links-mobile');
        }
        else{
            navMenu.current.classList.add('nav-links');
        }
    };

    return (
        <nav className="navbar">
            <div className='nav-container'>
                
               <div className='logo-part'>  
                <img src={logoImage} alt="website-logo" className="logoImage"/>
                <div className="logo" onClick={()=>{window.location.assign("/")}}>
                    <p className='logored'>Secure</p>
                    <p className='logowhite'>Circle</p>
                    <p className='logored'>Funds</p>
                    </div> 
               </div>
                <ul className= "nav-links" ref={navMenu}>
                    
                    <NavLink exact  to="/" onClick={()=>{if (window.innerWidth<768){toggleMenu()}}}>Home</NavLink>
                    <NavLink to="/faq"onClick={()=>{if (window.innerWidth<768){toggleMenu()}}}>FAQs</NavLink>
                    <NavLink to="/contact"onClick={()=>{if (window.innerWidth<768){toggleMenu()}}}>Contact</NavLink>
                    <NavLink to="/privacypolicy"onClick={()=>{if (window.innerWidth<768){toggleMenu()}}}>Privacy Policy</NavLink>
                    <NavLink to="/termsofservice"onClick={()=>{if (window.innerWidth<768){toggleMenu()}}}>Terms of Service</NavLink>
                    
                    
                   <div className='nav-button-wrapper'>
                   <button className='nav-button' onClick={()=>{navmove('/register'); {if (window.innerWidth<768){toggleMenu()}}}}>Register</button>
                    <button className='nav-button' onClick={()=>{navmove('/login'); {if (window.innerWidth<768){toggleMenu()}}  }}>Login</button>
                   </div>
                </ul>

                <div className="menu-icon" onClick={toggleMenu}>
                    {showNav ? <img src={timesButton} alt=""/> : <img src={Hambuger} alt=""/>} {/* Toggle between bars and times icons */}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
