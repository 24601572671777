import React, { useState } from 'react';
import '../styles/TabComponent.css'
import { bankName } from '../utils/constants';

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
 
  return (
    <div>
      <div className="tabs">
        <div
          className={`tab ${activeTab === 0 ? 'active' : ''}`}
          onClick={() => handleTabClick(0)}
        >
        <p className='tab-header'>Our Mission</p>
        </div>
        <div
          className={`tab ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => handleTabClick(1)}
        >
        <p className='tab-header'> Our Vision</p>
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 0 && 
          <div>
  The goal of {bankName} is to provide its users with a unique, safe, and secure platform for financial and fintech transactions. That’s why we have developed a cutting-edge platform with robust infrastructure, ensuring maximum convenience for our users.
</div>
        }



        {activeTab === 1 && 
        <div>
        <p>
          We continuously strive to enhance our technical capabilities and financial expertise by leveraging the latest and most advanced fintech technologies to simplify transactions.
        </p>
        <br />
        <p>
          {bankName} envisions expanding our reach to customers across diverse races, countries, and continents.
        </p>
      
       </div>}
      </div>
    </div>
  );
};

export default TabComponent;
