import React, { useEffect } from "react";
import Hero from "../Hero";
import aboutusImage from "../../images/about-us.png";
import "../../styles/Home.css";
import TabComponent from "../TabComponent";
import CardComponent1 from "../CardComponent1";
import CardComponent2 from "../CardComponent2";
import CountCard from "../CountCard";
import CardComponent3 from "../CardComponent3";
import CardComponent4 from "../CardComponent4";
import customer1 from "../../images/2.jpg";
import customer2 from "../../images/3.jpg";
import customer3 from "../../images/4.jpg";
import FAQs from "../FAQs";
import { bankName } from "../../utils/constants";

export default function Home() {
  return (
    <div className="home-container">
      <Hero />
      <div
        className="about-us-wrapper"
        style={{
          backgroundColor: "white",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
        data-aos="fade-up"
      >
        <div className="left">
          <img
            src={aboutusImage}
            alt=""
            data-aos="fade-up"
            data-aos-duration="2000"
          />
        </div>

        <div className="right">
          <div data-aos="fade-up">
            <h1 className="Theader">About Us</h1>
            <p>
              Some years ago, a visionary team set out to redefine banking by
              creating a savings app that anyone could access. This vision gave
              rise to {bankName} Bank. Today, we are more determined than ever
              as we introduce a Central Bank-licensed microfinance bank
              dedicated to helping you manage your finances effortlessly and
              affordably. At {bankName}, you'll find tools designed to track
              your expenses, grow your savings, and empower you to make smarter
              financial choices. No matter where you are or who you are, our
              mission is focused on your financial well-being. We recognize the
              challenges of traditional banking and are committed to providing a
              better, more seamless solution for everyone.
            </p>
          </div>
          <div>
            <TabComponent data-aos="fade-up" />
          </div>
        </div>
      </div>

      <div className="why-choose-us-container" data-aos="fade-up">
        <div className="why-choose-us-wrapper">
          <h1 className="Theader">Why Us?</h1>
          <p className="TDes">
            Here are some of the many features that define our uniqueness.
          </p>

          <div className="card-container">
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-regular fa-credit-card"></i>}
                header={"Flexible Payment Methods"}
                description={
                  "Choose from a wide range of payment options including Visa, MasterCard, bank transfers, cryptocurrency, and more."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-earth-europe"></i>}
                header={"Global Reach"}
                description={
                  "Our services are available in over 80% of countries worldwide, ensuring seamless access across continents."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-regular fa-money-bill-1"></i>}
                header={"Low Transaction Costs"}
                description={
                  "Enjoy highly competitive rates and minimal fees tailored for both individual and business customers."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-file-invoice-dollar"></i>}
                header={"Secure Financial Operations"}
                description={
                  "Protect your funds with our state-of-the-art technologies, safeguarding against fraud and cyber threats."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-shield-halved"></i>}
                header={"Enhanced Security"}
                description={
                  "Benefit from robust protection with advanced encryption and defense against DDoS attacks for your transactions."
                }
              />
            </div>
            <div data-aos="fade-up">
              <CardComponent1
                icon={<i class="fa-solid fa-phone-volume"></i>}
                header={"Around-the-Clock Support"}
                description={
                  "Our dedicated support team is available 24/7 to assist you with any issues or inquiries."
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* INTEREST RATES SECTION */}
      <div
        className="why-choose-us-container"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="why-choose-us-wrapper">
          <h1 className="Theader">Our Interest Rates</h1>
          <p className="TDes">
          We deeply understand our customers' needs, which is why we offer highly competitive and attractive interest rates.
          </p>
          <div className="ourservices-cards">
            <div data-aos="slide-right">
              <CardComponent2
                accountType="Personal"
                percentage="2%"
                minimumAmount="1,000"
                intervalType="Partial"
              />
            </div>

            <div data-aos="slide-left">
              <CardComponent2
                accountType="Corporate"
                percentage="3%"
                minimumAmount="10,000"
                intervalType="Fixed"
              />
            </div>
          </div>
        </div>
      </div>

      {/* A Summary of Our Journey */}
      <div className="our-journey" data-aos="fade-up" data-aos-duration="2000">
        <div className="why-choose-us-wrapper">
          <h1 className="Theader">A Summary of Our Journey</h1>
          <p className=" our-journey-description">
          Over the years, we have accumulated extensive experience, significantly enhancing our portfolio in global financial services.
          </p>

          <div className="count-cards-container">
            <div data-aos="fade-down">
              <CountCard
                icon={<i class="fa-solid fa-people-group"></i>}
                number={3000324}
                description="Active users"
              />
            </div>
            <div data-aos="fade-down">
              <CountCard
                icon={<i class="fa-regular fa-hourglass-half"></i>}
                number={80003}
                description="Running Days"
              />
            </div>
            <div data-aos="fade-down">
              <CountCard
                icon={<i class="fa-solid fa-award"></i>}
                number={400}
                description="Won Awards"
              />
            </div>
            <div data-aos="fade-down">
              <CountCard
                icon={<i class="fa-solid fa-map-location-dot"></i>}
                number={150}
                description="Global Pressence"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Our Breif History */}
      {/* <div className="our-history-container">
        <div className="our-history-wrapper">
          <div className="left">
            <h1 className="Theader">A brief history about us</h1>
            <p className="TDes">
              "FortressCapitalOnline," established in 2012 by socially-minded
              entrepreneurs, aimed to provide accessible financial services to
              low-income individuals and small businesses. Initially focusing on
              micro-loans for local micro-entrepreneurs, the company quickly
              expanded its operations to meet growing demand, opening branches
              in multiple cities. Offering a range of services including savings
              accounts, insurance, and money transfers, they also innovated with
              mobile banking solutions. A 2013 investment from a major venture
              capital firm facilitated further expansion, leading to becoming
              one of the country's largest microfinance companies by 2015.
              Throughout its journey, FortressCapitalOnline remained committed to
              empowering the underbanked and fostering economic growth.
            </p>
          </div>
          <div className="right">
            <YouTube videoId="Gc2en3nHxA4" className="youtube-video"/>
          </div>
        </div>
      </div> */}
      {/* Our Process is Simple and Short */}
      <div
        className="our-process-container"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="our-process-wraper">
          <h1 className="Theader process-header">
            Our Process is Simple and Short
          </h1>

          <div className="processes-container">
            <div
              data-aos="fade-in"
              data-aos-duration="2000"
              onClick={() => {
                window.location.assign("/register");
              }}
            >
              <CardComponent3
                number={1}
                icon={<i class="fa-solid fa-user-plus"></i>}
                header={"Create Your Account"}
                description={
                 "Start by signing up and completing identity verification to quickly set up your new account."
                }
              />
            </div>
            <div data-aos="fade-in" data-aos-duration="4000">
              <CardComponent3
                number={2}
                icon={<i class="fa-solid fa-sliders"></i>}
                header={"Customize Your Profile"}
                description={
                 "Once logged in, personalize your account by creating a new wallet, receiving a unique account number, and setting up a transaction PIN."
                }
              />
            </div>
            <div data-aos="fade-in" data-aos-duration="6000">
              <CardComponent3
                number={3}
                icon={<i class="fa-solid fa-money-bill-transfer"></i>}
                header={"Add Funds to Your Account"}
                description={
                  "Make your first deposit to activate transactions and gain access to our comprehensive range of services. Enjoy the peace of mind that comes with our secure payment system, designed to protect your funds and personal information."
                }
              />
            </div>
            <div data-aos="fade-in" data-aos-duration="8000">
              <CardComponent3
                number={4}
                icon={<i class="fa-solid fa-file-invoice-dollar"></i>}
                header={"Engage in Transactions"}
                description={
                  "Explore our smooth and effortless services, enabling you to complete a wide range of transactions with ease."
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/*What Our Clients Say About Us*/}
      <div
        className="client-reviews"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="client-reviews-wrapper">
          <h1 className="Theader">What Our Clients Say About Us</h1>
          <p className="TDes review-description">
            {/* START HERE */}
            Our expertise in financial services has significantly improved the lives of our clients, and their testimonials have been a great source of encouragement for us.
          </p>

          <div className="review-container">
            <div data-aos="fade-down">
              <CardComponent4
               review={` ${bankName} stands out as an exceptional microfinance company. Their transaction process is smooth and highly efficient!`}  
                image={customer1}
                name={`${bankName}`}
              />
            </div>
            <div data-aos="fade-down">
              <CardComponent4
                review={
                  "A fantastic bank with a clear vision! Their customer service is exceptional, and the incredibly low transaction fees make them even more remarkable."
                }
                image={customer2}
                name={bankName}
              />
            </div>
            <div data-aos="fade-down">
              <CardComponent4
              review={`Absolutely amazing! One of the best services I've experienced. You can confidently trust ${bankName} with your deposits. Their loans are both helpful and incredibly convenient.`}  

                image={customer3}
                name={bankName}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="FAQs-container"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="FAQ-wrapper">
          <FAQs />
        </div>
      </div>

      <div
        className="Get-Started-container"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="get-started-wrapper">
          <div className="left">
            <div className="form-holder">
              <h1 className="Theader">Ready to get started?</h1>
              <form>
                <input placeholder="Enter Name *" />
                <input placeholder="Enter Email *" />
                <input placeholder="Enter Subject" />
                <textarea rows={5} placeholder="Message Body *"></textarea>

                <button>Send Message</button>
              </form>
            </div>
          </div>
          <div className="right">
            <h1 className="Theader">
              Looking for an easy and secured place to save your money?
            </h1>
            <p className="TDes">
              Search no more! Click on the button below to get started.
            </p>
            <button
              className="btn-get-solution"
              onClick={() => {
                window.location.assign("/faq");
              }}
            >
              Get Your Solution
            </button>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}
