import React from "react";
import Collapsible from "react-collapsible";
import "../styles/FAQs.css";
import { bankName } from "../utils/constants";

function FAQs() {
  return (
    <div className="FAQs-Main">
      <h1 className="Theader">Frequently Asked Questions</h1>
      <p className="TDes">Have Any Question?</p>
      <div className="FAQ-List">
        <div className="left" data-aos="slide-right">
          <Collapsible
            trigger={
              <button className="custom-trigger">
                What is {bankName.replaceAll(" ","")} Bank?
              </button>
            }
            transitionTime={200} // Adjust the transition time
            contentOuterClassName="custom-content-outer" // Class for the outer container of the content
            contentInnerClassName="custom-content-inner" // Class for the inner container of the content
          >
            <p className="custom-content">
            {bankName.replaceAll(" ","")} Bank is a digital banking platform that offers various financial services, including online accounts, transactions, savings, and investments.
            </p>
          </Collapsible>

          <Collapsible
            trigger={
              <button className="custom-trigger">How do I open an account?</button>
            }
            transitionTime={200} // Adjust the transition time
            contentOuterClassName="custom-content-outer" // Class for the outer container of the content
            contentInnerClassName="custom-content-inner" // Class for the inner container of the content
          >
            <p className="custom-content">
              Opening an account with {bankName.replaceAll(" ","")} Bank is easy. Simply visit our website, click on the "Open an Account" button, and follow the instructions to complete the registration process.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <button className="custom-trigger">
                How can I deposit funds into my account?
              </button>
            }
            transitionTime={200} // Adjust the transition time
            contentOuterClassName="custom-content-outer" // Class for the outer container of the content
            contentInnerClassName="custom-content-inner" // Class for the inner container of the content
          >
            <p className="custom-content">
              You can deposit funds into your {bankName.replaceAll(" ","")} Bank account through various methods, including bank transfers, mobile check deposits, and direct deposits. Simply choose the preferred method and follow the instructions provided.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <button className="custom-trigger">
                How secure is {bankName.replaceAll(" ","")} Bank?
              </button>
            }
            transitionTime={200} // Adjust the transition time
            contentOuterClassName="custom-content-outer" // Class for the outer container of the content
            contentInnerClassName="custom-content-inner" // Class for the inner container of the content
          >
            <p className="custom-content">
            {bankName.replaceAll(" ","")} Bank employs advanced security measures to protect your account and personal information. We use encryption technology, multi-factor authentication, and regular security updates to ensure the highest level of security for our customers.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <button className="custom-trigger">
                Can I access my account from mobile devices?
              </button>
            }
            transitionTime={200} // Adjust the transition time
            contentOuterClassName="custom-content-outer" // Class for the outer container of the content
            contentInnerClassName="custom-content-inner" // Class for the inner container of the content
          >
            <p className="custom-content">
              Yes, {bankName.replaceAll(" ","")} Bank offers mobile banking services. You can access your account, check balances, make transfers, and perform other banking activities conveniently from your mobile device using our mobile app or mobile-optimized website.
            </p>
          </Collapsible>
        </div>
        <div className="right" data-aos="slide-left">
          <Collapsible
            trigger={
              <button className="custom-trigger">
                What security measures should I take to protect my account?
              </button>
            }
            transitionTime={200} // Adjust the transition time
            contentOuterClassName="custom-content-outer" // Class for the outer container of the content
            contentInnerClassName="custom-content-inner" // Class for the inner container of the content
          >
            <p className="custom-content">
              To ensure the security of your {bankName.replaceAll(" ","")} Bank account, we recommend using strong, unique passwords, enabling multi-factor authentication, avoiding public Wi-Fi for banking activities, and regularly monitoring your account for any suspicious activity.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <button className="custom-trigger">
               How do I contact customer support?
              </button>
            }
            transitionTime={200} // Adjust the transition time
            contentOuterClassName="custom-content-outer" // Class for the outer container of the content
            contentInnerClassName="custom-content-inner" // Class for the inner container of the content
          >
            <p className="custom-content">
              You can contact {bankName.replaceAll(" ","")} Bank's customer support team through our website, mobile app, or by phone. Our dedicated support representatives are available to assist you with any inquiries or issues you may have regarding your account or our services.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <button className="custom-trigger">
               What are the fees associated with {bankName.replaceAll(" ","")} Bank accounts?
              </button>
            }
            transitionTime={200} // Adjust the transition time
            contentOuterClassName="custom-content-outer" // Class for the outer container of the content
            contentInnerClassName="custom-content-inner" // Class for the inner container of the content
          >
            <p className="custom-content">
            {bankName.replaceAll(" ","")} Bank offers transparent fee structures for its accounts. Common fees may include monthly maintenance fees, overdraft fees, and ATM fees. Please refer to our fee schedule or contact customer support for detailed information on account fees.
            </p>
          </Collapsible>
        </div>
      </div>
    </div>
  );
}

export default FAQs;
