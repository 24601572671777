import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink, useNavigate } from "react-router-dom";
import { bankName, userscount } from "../utils/constants";

const MySlider = () => {
  const navmove = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 6000,
  };

  return (
    <Slider {...settings}>
      <div>
        <div className="sider-content">
          <h1>EASY AND SECURED DIGITAL BANKING</h1>
          <p className="herodescription">
            <span className="herowebname">
              {bankName.toLocaleUpperCase().replaceAll(" ", "")}
            </span>{" "}
            offers a secure and reliable platform for performing transactions
            worldwide. Wherever you are, you can save and transact with ease,
            convenience, and confidence, connecting seamlessly with people
            across the globe.
          </p>

          <ul className="service-list">
            <li>
              <i class="fa-solid fa-square-check"></i>
              <p>Personal Banking</p>
            </li>
            <li>
              <i class="fa-solid fa-square-check"></i>
              <p>Corporate Banking</p>
            </li>
            <li>
              <i class="fa-solid fa-square-check"></i>
              <p>SME Banking</p>
            </li>
            <li>
              <i class="fa-solid fa-square-check"></i>
              <p>Personal Loans</p>
            </li>
          </ul>

          <div className="hero-button-wrapper">
            <div
              className="button-div"
              onClick={() => {
                navmove("/register");
              }}
            >
              Sign Up
            </div>
            <div
              className="button-div"
              onClick={() => {
                navmove("/login");
              }}
            >
              Login
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="sider-content">
          <h1>FAST AND STABLE BANKING</h1>
          <p>
            Join over {userscount} satisfied users worldwide who rely on our
            trusted services. Our platform enables secure and confident
            transactions for everyone. With substantial technological
            advancements and generous contributions, we continue to uphold
            unwavering consumer and investor confidence.
          </p>

          <ul className="service-list">
            <li>
              <i class="fa-solid fa-square-check"></i>
              <p>Personal Banking</p>
            </li>
            <li>
              <i class="fa-solid fa-square-check"></i>
              <p>Corporate Banking</p>
            </li>
            <li>
              <i class="fa-solid fa-square-check"></i>
              <p>SME Banking</p>
            </li>
            <li>
              <i class="fa-solid fa-square-check"></i>
              <p>Personal Loans</p>
            </li>
          </ul>

          <div className="hero-button-wrapper">
            <div
              className="button-div"
              onClick={() => {
                navmove("/faq");
              }}
            >
              Know More
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default MySlider;
